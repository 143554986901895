<template>
  <div>
  <MasterHeader />
    <div v-if="!isLoaded" class="loader">Loading...</div>
    <div class="container container-wide" v-if="isLoaded">
      <div class="row">
        <div class="col-12">
          <div  class="mb-1">
            <router-link to="/dashboard/" class="btn btn-sm btn-link"
              ><i class="fas fa-angle-left"></i> My
              Dashboard</router-link
            >
          </div>
          <div class="widget">
            <div class="widget-splash splash-line"></div>
              <div class="widget-header">
                <h4>Thesis slots overview</h4>
              </div>
              <div class="widget-body">
                <div class="thesis-body">
                  <p class="text-bold d-inline">
                    Your subscription:
                  </p>
                  <p class = "d-inline">
                    {{user.subscriptionPlan}} subscription 
                  </p>
                  <br>
                  <p class = "text-bold d-inline">
                    Allows for:
                  </p>
                  <p class = "d-inline" v-if="thesisSlots">
                    {{thesisSlots[0]}} thesis slots pr. semester / half year
                  </p>
                  <br>
                  <p class="mt-3">
                      This overview shows your allocation of thesis slots <br> You can always upgrade to expend the amount of thesis slots available
                  </p>
                  <router-link to="/subscription"
                    class= "thesis-button"
                  >
                  Upgrade
                  </router-link>
                  <div class="thesis-table m-5">
                    <!-- {{companyCollaborations}} -->
                    <table class="table text-center">
                      <thead>
                        <tr v-if="thesisSlots">
                          <th scope="col"></th>
                          <th v-for="ts in thesisSlots.slice(1)" :key="ts[0]" scope="col">{{ts[0]}}</th>
                        </tr>
                      </thead>
                      <tbody v-if="thesisSlots">
                        <tr v-for="ts in thesisSlots[0]" :key="ts[0]">
                          <td>Thesis Slot {{ts}}</td>
                          <td v-for="ts in thesisSlots.slice(1)" :key="ts[0]" scope="col">
                            <span @click="goToCollaboration($event)">
                            <router-link :to="'/collaborations/' + thesisLink(ts)">
                              {{thesisSlotStatus(ts)}}
                            </router-link>
                              </span>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MasterHeader from "@/components/MasterHeader.vue";
import { mapState } from "vuex";
import { base64ToString } from "../../utils/utils";

let callCounter = 0;
export default {
  components: {
    MasterHeader,
  },
  data(){
    return {
      hasCompany: false,
      isLoaded: false,
      companyCollaborations: null
    }
  },
  computed: {
    ...mapState(["user"]),
    // ...mapActions(['']),
  thesisSlots: function(){
    const slots = this.$store.getters.allTokenSlots;
    return slots;
  },
    userCompany(){
    return this.$store.getters.company
  },
    userSubscriptionId() {
      return this.$store.getters.userSubscriptionId;
    },
  },
    created() {
    // const userType = localStorage.getItem('i_am')
    // const userVerified = localStorage.getItem('u-verified')
    let userType = localStorage.getItem('i_am')
    userType = base64ToString(userType)
    let userVerified = localStorage.getItem('u-verified')
    if (userVerified == 0 && userType == 'student') {
      this.$router.push('/user/student-welcome')
    }
    if (userVerified == 0 && userType == 'professional') {
      this.$router.push('/user/welcome')
    }
  },
  mounted: function () {
    this.$store.dispatch("getCompanyCollaborationsById", this.$store.getters.company).then(
        this.companyCollaborations = this.$store.getters.companyCollaborations
      )
    setTimeout(() => {
      this.setAllThesis()
      this.isLoaded = true;
    }, 3000);
  },
  // watch: { //! watcher not getting triggered when property is updated, workaround in mounted as a timeout for now
  //   userSubscriptionId(value) {
  //     this.isLoaded = true;
  //     this.setAllThesis()
  //   }
  // },
  methods: {
    goToCollaboration: function (e) {
      // console.log(e.target, 'val')
      // const id = e.target
      // console.warn("🚀 ~ file: TokenDetails.vue ~ line 121 ~ id", id.replace(/<\/?[^>]+>/ig, " "))
      // let elem = id.toString().split('>')[1].split('<')[0]
      // console.warn("🚀 ~ file: TokenDetails.vue ~ line 121 ~ elem", elem)
      // this.$router.push("/collaboration");
    },
    setAllThesis: function () {
      const data = { 
        company: this.userCompany,
        subscription: this.userSubscriptionId
      }
    this.$store.dispatch('getAllThesisSlots', data)
    },
    thesisSlotStatus: function (slotData) {
      const slotYear = slotData[0].split(' ')[1];
      const slotSession = slotData[0].split(' ')[0];
      const year = new Date().getFullYear(); // current year to compare with the thesis data from the api
      const month = new Date().getMonth()+1;  // current month to compare with the thesis data from the api
      
      //! please do not make any changes to the isCurrentSession variable. This will affect the entire output of the method.
      let isCurrentSession = (month >=7 && slotSession == 'fall' && slotYear >= year)? true : 
      (month >=7 && slotSession == 'fall' && slotYear < year) ? false : 
      (month >= 7 && slotSession == 'spring' && slotYear <= year) ? false : 
      (month >=7 && slotSession == 'spring' && slotYear > year) ? true : 
      (month <=6 && slotSession == 'fall' && slotYear >= year)? true : 
      (month <=6 && slotSession == 'fall' && slotYear < year) ? false : 
      (month <=6 && slotSession == 'spring' && slotYear < year) ? false : 
      (month <=6 && slotSession == 'spring' && slotYear == year) ? true : 
      (month <=6 && slotSession == 'spring' && slotYear > year) ? true : 'God help me'

      callCounter+=1 // global counter to check when 8 iterations are complete.

      if(callCounter>=8){ // reset the counter after the 8th iteration
        this.thesisSlots[0] = this.thesisSlots[0]-1 // changing the thesisSlots[0] to calculate the thesis slots below
        callCounter = 0;
      }
      if (this.thesisSlots[0] - slotData[1] > 0) {
        // console.log(this.companyCollaborations)
        // let myLink = null;
        // this.companyCollaborations.filter(item => item.offer.collaborationTargetSemester.toLowerCase() === slotData[0].toLowerCase() ? myLink = item.offer.collaborationId : null)
        // TODO will add the code to make the current slot a link and redirect to the collaboration for the company/user
        return 'collaboration'
      }else if(this.thesisSlots[0]-slotData[1] <= 0 && slotYear >= year && isCurrentSession){
        // console.log('not used', slotData)
        return 'Free'
      }else if(this.thesisSlots[0]-slotData[1] <= 0 && slotYear <= year && isCurrentSession){
        // console.log('not used', slotData)
        return 'Free'
      }else{
        // console.log('not available', slotData)
        return 'NA'
      }
    },
    thesisLink: function (thesisSession) {
        let myLink = null;
        this.companyCollaborations.filter(item => item.offer.collaborationTargetSemester.toLowerCase() === thesisSession[0].toLowerCase() ? myLink = item.offer.collaborationId : null)
        return myLink
    }
  }
}
</script>
<style scoped>
  .thesis-body{
  padding: 2rem;
  background: #fff;
  -webkit-box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  box-shadow: 0 1px 4px rgb(0 0 0 / 15%);
  border-radius: 4px;
  margin: 0;
}
.text-bold{
  font-weight: 700
}
.thesis-button {
  list-style: none;
  border: 1px solid #da518a;
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  color: #da518a;
  font-size: 14px;
  background: transparent;
}
table tr th:not(:last-child), td:not(:last-child){
  border-right: 1px solid #dee2e6
  
}
table tr th:not(:first-child){
  background-color: #FCFCFC;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(219,95,138, 0.2);
  border-right: 1.1em solid rgba(219,95,138, 0.2);
  border-bottom: 1.1em solid rgba(219,95,138, 0.2);
  border-left: 1.1em solid #db5f8a;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>